<template>
    <StaticStep v-bind="$attrs" v-on="$listeners">
        <template slot="title">
            <h2 class="linkover-notification__title">
                {{ $t('proceedTicketmaster') }}
            </h2>
        </template>
        <template slot="icon">
            <BaseIcon symbol-id="icons--ticketmaster_logo_round" class="linkover-notification__icon" />
        </template>
        <template slot="button">
            <BaseButton classes="linkover-notification__button"
                :loading="loading"
                :disabled="loading"
                :placeholder="$t( '_common:buttons.continue' )"
                @click="$emit('openProviderLink')" />
        </template>
    </StaticStep>
</template>

<script>
    import BaseIcon from '@core/shared/components/misc/BaseIcon.vue';
    import BaseButton from '@core/shared/components/buttons/BaseButton';
    import StaticStep from './StaticStep';

    export default {
        name: 'ProceedToProviderStep',
        components: { StaticStep, BaseButton, BaseIcon },
        i18nOptions: {
            namespaces: 'main',
            keyPrefix: 'views.event.theEvent._components.linkOverNotification'
        },
        props: {
            busEvent: {
                type: Object,
                required: true,
            },
            loading: {
                type: Boolean,
                default: false,
            },
        },
    };
</script>
