<template>
    <div class="linkover-notification u-position-relative">
        <v-btn
            v-if="$vuetify.breakpoint.mdAndUp"
            fab
            text
            x-small
            class="linkover-notification__close u-position-absolute"
            @click="onCloseButtonDo">
            <BaseIcon symbol-id="icons--close" />
        </v-btn>
        <div v-else class="linkover-notification__close-mobile d-flex align-center">
            <v-btn
                text
                x-small
                class="button-clone"
                @click="onCloseButtonDo">
                {{ $t( '_common:buttons.cancel' ) }}
            </v-btn>
        </div>

        <Control
            class="linkover-notification__control"
            :bus-events="busEvent"
            :control-by-route="false"
            :steps="steps"
            :destroy-steps="false"
            @change-index="currentStep=$event">
            <template v-if="displayEducationalSteps">
                <ChooseSeatStep slot="step0" :bus-event="busEvent" :total-steps="totalCountedSteps" :current-step="currentStep" />
                <CompletePurchaseStep slot="step1" :bus-event="busEvent" :total-steps="totalCountedSteps" :current-step="currentStep" />
                <PointsAddedStep slot="step2" :bus-event="busEvent" :total-steps="totalCountedSteps" :current-step="currentStep" @next="busEvent.$emit('next')" />
                <ProceedToProviderStep slot="step3"
                    :bus-event="busEvent"
                    :total-steps="totalCountedSteps"
                    :current-step="currentStep"
                    :display-stepper="false"
                    @openProviderLink="onProceedToTicketmasterDo" />
            </template>
            <template v-else>
                <ProceedToProviderStep slot="step0"
                    :bus-event="busEvent"
                    :total-steps="totalCountedSteps"
                    :current-step="currentStep"
                    :display-stepper="false"
                    :loading="loading"
                    @openProviderLink="onProceedToTicketmasterDo" />
            </template>
        </Control>
    </div>
</template>


<script>
    import Vue from 'vue';
    import { mapActions } from 'vuex';
    import Control from '@core/shared/components/wizard/Control.vue';
    import BaseIcon from '@core/shared/components/misc/BaseIcon.vue';
    import {
        saveEducationalWizardDisplayed,
    } from '@core/utils/purchaseFlowUtils';
    import purchaseFlowConstants from '@core/utils/constants/purchaseFlow';
    import LogService from '@core/services/LogService';
    import CompletePurchaseStep from './LinkOverNotificationContent/CompletePurchaseStep';
    import ChooseSeatStep from './LinkOverNotificationContent/ChooseSeatStep';
    import PointsAddedStep from './LinkOverNotificationContent/PointsAddedStep';
    import ProceedToProviderStep from './LinkOverNotificationContent/ProceedToProviderStep';


    export default {
        name: 'LinkOverNotificationNoAuthentication',
        components: {
            CompletePurchaseStep,
            ChooseSeatStep,
            Control,
            PointsAddedStep,
            BaseIcon,
            ProceedToProviderStep
        },
        i18nOptions: {
            namespaces: 'main',
            keyPrefix: 'views.event.theEvent._components.linkOverNotification'
        },
        props: {
            displayEducationalSteps: {
                type: Boolean,
                default: true
            },
            eventId: {
                type: Number,
                required: true
            },
        },
        data() {
            return {
                currentStep: 0,
                busEvent: new Vue( ),
                loading: false,
            };
        },
        computed: {
            steps() {
                if( this.displayEducationalSteps ) {
                    return [
                        {
                            slot: 'step0',
                            index: 0,
                            count: true
                        },
                        {
                            slot: 'step1',
                            index: 1,
                            count: true
                        },
                        {
                            slot: 'step2',
                            index: 2,
                            count: true
                        },
                        {
                            slot: 'step3',
                            index: 3,
                            count: false
                        }
                    ];
                }

                if( !this.displayEducationalSteps )  {
                    return [ {
                        slot: 'step0',
                        index: 0,
                        count: false
                    } ];
                }

                return this.completeSteps;
            },
            completeSteps() {
                return [
                    {
                        slot: 'step0',
                        index: 0,
                        count: true
                    },
                    {
                        slot: 'step1',
                        index: 1,
                        count: true
                    },
                    {
                        slot: 'step2',
                        index: 2,
                        count: true
                    },
                    {
                        slot: 'step3',
                        index: 3,
                        count: false
                    }
                ];
            },
            totalCountedSteps() {
                return this.steps.filter( step => step.count ).length;
            },
        },
        watch: {
            currentStep( newValue ) {
                if ( newValue === this.steps.length - 1 ) {
                    this.setEducationalWizardCompleted( );
                }
            }
        },
        methods: {
            ...mapActions( {
                getLinkOverUrl: 'events/getLinkOverUrl'
            } ),
            setEducationalWizardCompleted() {
                saveEducationalWizardDisplayed( purchaseFlowConstants.USER_TYPES.LOGGED_OUT, true );
                this.busEvent.$emit( 'next' );
            },
            async onCloseButtonDo() {
                if( this.currentStep === this.steps.length - 1 ) {
                    this.setEducationalWizardCompleted();
                }

                this.$emit( 'close' );
            },
            getLastStepIndex() {
                return this.steps[ this.steps.length - 1 ].index;
            },
            async onProceedToTicketmasterDo( ) {
                this.loading = true;
                const url = await this.getLinkOverUrl( this.eventId );

                // eslint-disable-next-line no-console
                LogService.info( '[GST] redirecting to: ', url );

                if( url ) {
                    window.location.href = url;
                } else {
                    LogService.warn( '[GST] no url found for the eventId=', this.eventId );
                    this.loading = false;
                    this.$emit( 'error-link-over' );
                    this.$emit( 'close' );
                }

            }
        },
    };
</script>

<style lang="scss">
@import "@scssVariables";
@import "@scssMixins";

.linkover-notification {
    height: 100%;
    width: 100%;
    padding: theme-spacing( 13, 8 );
    background: theme-color( 'white' );
    border-radius: border-radius( 'm' );

    .linkover-notification__close {
        top: 22px;
        right: 22px;
        height: 12px;
        width: 12px;

        .gst-svg-icon {
            fill: theme-color( 'tertiary' );
        }
    }

    .linkover-notification__title {
        line-height: 40px;
        margin-bottom: theme-spacing( 8 );
        font-size: font-size( 'xxxxxxl' );
        font-weight: font-weight( 'bold' );
        text-align: center;
    }

    .linkover-notification__icon {
        height: 144px;
        width: auto;
        margin-bottom: theme-spacing( 10 );
    }

    .linkover-notification__button {
        width: 100% !important;
        margin: theme-spacing( 8, 0 );
    }

    .linkover-notification__control {
        height: 100%;

        > div {
            height: 100%;

            .wrapper {
                height: 100%;
            }
        }
    }

    @include mobile-only {
        padding: theme-spacing( 0 );
        border-radius: border-radius( 'm', 'm', 'none', 'none' );

        .linkover-notification__control {
            padding: theme-spacing( 8, 4, 13, 4 );
        }

        .linkover-notification__close-mobile {
            height: 52px;
            border-bottom: 1px solid rgba( theme-color-hex( 'tertiary' ), 0.2 );

            .button-clone {
                color: theme-color( 'primary' );
                font-size: font-size( 'm' );
                font-weight: font-weight( 'medium' );
                text-transform: none;
            }
        }

        .linkover-notification__title {
            line-height: 28px;
            font-size: font-size( 'xxxl' );
        }

        .linkover-notification__icon {
            height: 80px;
            width: auto;
            margin-bottom: theme-spacing( 6 );
        }

        .linkover-notification__button {
            margin: theme-spacing( 6, 0 );
        }
    }
}

@include mobile-only {
    .gst-v-dialog-link-over-notification {
        overflow-y: scroll !important;
    }
}
</style>
