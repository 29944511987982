<template>
    <div class="static-step d-flex flex-column align-center align-content-space-between">
        <slot name="icon">
        </slot>
        <slot name="title" class="static-step__title">
            Title
        </slot>
        <slot name="step-progress">
            <div v-if="displayStepper" class="static-step__progress d-flex">
                <i v-for="(index) in totalSteps" :key="index" :class="['step', 'd-block', index - 1 === currentStep && 'current']"></i>
            </div>
        </slot>
        <slot name="button" class="static-step__button">
            Button
        </slot>
        <slot name="footer" class="static-step__footer">
            <div class="static-step__footer d-flex justify-center">
                <BaseIcon symbol-id="logo_ticketmaster" class="icon" />
                <TenantLogoIcon class="icon tenant-logo" />
            </div>
        </slot>
    </div>
</template>


<script>
    import BaseIcon from '@core/shared/components/misc/BaseIcon.vue';

    export default {
        name: 'StaticStep',
        components: {
            BaseIcon
        },
        props: {
            totalSteps: {
                type: Number,
                required: true,
            },
            currentStep: {
                type: Number,
                required: true,
            },
            displayStepper: {
                type: Boolean,
                default: true,
            },
        },
    };
</script>

<style lang="scss">
@import "@scssVariables";
@import "@scssMixins";

.static-step {
    .static-step__footer {
        flex-direction: row-reverse;
        align-items: center;

        .icon {
            height: 40px;
            margin: theme-spacing( 0, 4 );
        }
    }

    .static-step__progress {
        .step {
            height: theme-spacing( 2 );
            width: theme-spacing( 2 );
            margin-right: theme-spacing( 1 );
            background-color: theme-color( 'senary' );
            border-radius: theme-spacing( 2 );

            &.current {
                background-color: theme-color( 'primary' );
            }
        }
    }
}
</style>
