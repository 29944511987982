<template>
    <StaticStep v-bind="$attrs" v-on="$listeners">
        <template slot="title">
            <h2 class="linkover-notification__title">
                {{ pointsAddedTitle }}
            </h2>
        </template>
        <template slot="icon">
            <BaseIcon symbol-id="icons--earn_rewards" class="linkover-notification__icon" />
        </template>
        <template slot="button">
            <BaseButton classes="linkover-notification__button"
                :placeholder="buttonText"
                @click="$emit('next')" />
        </template>
    </StaticStep>
</template>

<script>
    import { mapGetters } from 'vuex';
    import BaseButton from '@core/shared/components/buttons/BaseButton';
    import BaseIcon from '@core/shared/components/misc/BaseIcon.vue';
    import StaticStep from './StaticStep';

    export default {
        name: 'PointsAddedStep',
        components: { StaticStep, BaseIcon, BaseButton },
        i18nOptions: {
            namespaces: 'main',
            keyPrefix: 'views.event.theEvent._components.linkOverNotification.pointsAdded'
        },
        props: {
            loggedIn: {
                type: Boolean,
                default: false
            }
        },
        computed: {
            ...mapGetters( {
                getLoyaltyProgramName: 'appTenant/getLoyaltyProgramName'
            } ),
            pointsAddedTitle() {
                return this.$t( 'title', { programName: this.getLoyaltyProgramName } );
            },
            buttonText() {
                return this.loggedIn ? this.$t( '_common:buttons.next' ) : this.$t( 'button' );
            }
        }
    };
</script>
