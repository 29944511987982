import localStorageService from '@core/services/LocalStorageService';

export const saveEducationalWizardDisplayed =  ( userEmail, value ) => {
    const educationalFlow = localStorageService.educationalFlowProvider.get();

    const newEducationalFlow = {
        ...educationalFlow,
        [userEmail]: value
    };

    localStorageService.educationalFlowProvider.set( newEducationalFlow );
};

export const getEducationalWizardDisplayed = ( userId ) => {
    const educationalFlowData = localStorageService.educationalFlowProvider.get();

    return !!educationalFlowData && !!educationalFlowData[userId];
};

export const getEducationalFlowInitiated = ( ) => {
    return localStorageService.educationalFlowInitiatedProvider.get();
};

export const setEducationalFlowInitiated = ( value ) => {
    return localStorageService.educationalFlowInitiatedProvider.set( value );
};
