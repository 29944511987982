import {
    getSetting as getSettingStorageUtils,
    saveSetting as saveSettingStorageUtils
} from '@core/utils/storageUtils';

const LOCAL_STORAGE_KEYS = {
    EDUCATIONAL_FLOW: 'educationalFlow',
    USER_LOGIN_EMAIL: 'userLoginEmail',
    EDUCATIONAL_FLOW_INITIATED: 'educationalFlowInitiated'
};

export default {
    educationalFlowProvider: {
        get() {
            const educationalFlow = getSettingStorageUtils( LOCAL_STORAGE_KEYS.EDUCATIONAL_FLOW );
            if( educationalFlow ) {
                return educationalFlow.value;
            }
        },
        set( educationalFlow ) {
            const expireTime = 30 * 12 * 60 * 60 * 1000; // 30 days
            saveSettingStorageUtils(  LOCAL_STORAGE_KEYS.EDUCATIONAL_FLOW, educationalFlow, expireTime );
        },
    },
    educationalFlowInitiatedProvider: {
        get() {
            return getSettingStorageUtils( LOCAL_STORAGE_KEYS.EDUCATIONAL_FLOW_INITIATED );
        },
        set( educationalFlowInitiated ) {
            saveSettingStorageUtils(  LOCAL_STORAGE_KEYS.EDUCATIONAL_FLOW_INITIATED, educationalFlowInitiated );
        }
    },
    // /**
    //  * Deprecated..it will be use in the future when we will drop vuex-persist
    //  */
    // userLoginEmailProvider: {
    //     get() {
    //         const userLogin = getSettingStorageUtils( LOCAL_STORAGE_KEYS.USER_LOGIN_EMAIL );
    //         if( userLogin ) {
    //             return userLogin.value;
    //         }
    //     },
    //     set( userLogin ) {
    //         const expireTime = 90 * 24 * 60 * 60 * 1000; // 90 days
    //         saveSettingStorageUtils(  LOCAL_STORAGE_KEYS.USER_LOGIN_EMAIL, userLogin, expireTime );
    //     },
    //     delete() {
    //         saveSettingStorageUtils(  LOCAL_STORAGE_KEYS.USER_LOGIN_EMAIL, null );
    //     },
    // },
};
