<template>
    <LinkOverNotificationWithAuthentication
        v-if="hasTenantAuthentication"
        v-bind="$attrs"
        :display-educational-steps="displayEducationalSteps"
        :user-should-login="userShouldLogin"
        :event-id="eventId"
        v-on="$listeners"
        @close="$emit( 'close' )"
        @error-link-over="onErrorLinkOverDo" />
    <LinkOverNotificationNoAuthentication
        v-else
        v-bind="$attrs"
        :display-educational-steps="displayEducationalSteps"
        :event-id="eventId"
        v-on="$listeners"
        @close="$emit( 'close' )"
        @error-link-over="onErrorLinkOverDo" />
</template>

<script>
    import { mapGetters } from 'vuex';
    import {
        getEducationalFlowInitiated,
        getEducationalWizardDisplayed,
        setEducationalFlowInitiated
    } from '@core/utils/purchaseFlowUtils';
    import purchaseFlowConstants from '@core/utils/constants/purchaseFlow';
    import CloseModalOnRouteChangeMixin from '@core/mixins/modals/CloseModalOnRouteChange';
    import LinkOverNotificationNoAuthentication
        from './LinkOverNotification/LinkOverNotificationNoAuthentication.vue';
    import LinkOverNotificationWithAuthentication from './LinkOverNotification/LinkOverNotificationWithAuthentication.vue';

    export default {
        name: 'LinkOverNotification',
        components: { LinkOverNotificationNoAuthentication, LinkOverNotificationWithAuthentication },
        mixins: [ CloseModalOnRouteChangeMixin ],
        props: {
            id: {
                type: String,
                default: ''
            },
            userIsAuth: {
                type: Boolean,
                default: false
            },
            busEventParent: {
                type: Object,
                required: true
            }
        },
        data () {
            return {
                displayEducationalSteps: null,
                userShouldLogin: null,
            };
        },
        computed: {
            ...mapGetters( {
                hasTenantAuthentication:            'appTenant/hasAuthentication',
                userEmail:                          'user/email',
            } ),
            eventId() {
                return parseInt( this.id );
            },

        },
        watch: {
            'userIsAuth': function ( value ) {
                if( value ) {
                    this.initLinkOverNotification();
                }
            }
        },
        methods: {
            initLinkOverNotification() {
                const userLoggedInEmail = this.userEmail;
                const wasEducationalWizardDisplayed = userLoggedInEmail ?  getEducationalWizardDisplayed( userLoggedInEmail ) : getEducationalWizardDisplayed( purchaseFlowConstants.USER_TYPES.LOGGED_OUT );
                if( this.hasTenantAuthentication && userLoggedInEmail ) {
                    const educationalFlowInitiated = getEducationalFlowInitiated();
                    this.displayEducationalSteps =  educationalFlowInitiated ? false : !wasEducationalWizardDisplayed;
                    setEducationalFlowInitiated( false );
                } else {
                    this.displayEducationalSteps = !wasEducationalWizardDisplayed;
                }
                this.userShouldLogin =  !userLoggedInEmail && !this.userIsAuth;
            },
            onErrorLinkOverDo( ) {
                this.busEventParent.$emit( 'error-link-over' );
            }
        },
        mounted() {
            this.initLinkOverNotification();
        }
    };
</script>
