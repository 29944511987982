<template>
    <StaticStep v-bind="$attrs" v-on="$listeners">
        <template slot="title">
            <h2 class="linkover-notification__title">
                {{ $t('completePurchase') }}
            </h2>
        </template>
        <template slot="icon">
            <BaseIcon symbol-id="icons--payment_completed" class="linkover-notification__icon" />
        </template>
        <template slot="button">
            <BaseButton classes="linkover-notification__button"
                :placeholder="$t( '_common:buttons.next' )"
                @click="busEvent.$emit('next')" />
        </template>
    </StaticStep>
</template>

<script>
    import BaseIcon from '@core/shared/components/misc/BaseIcon.vue';
    import BaseButton from '@core/shared/components/buttons/BaseButton';
    import StaticStep from './StaticStep';

    export default {
        name: 'CompletePurchaseStep',
        components: { StaticStep, BaseIcon, BaseButton },
        i18nOptions: {
            namespaces: 'main',
            keyPrefix: 'views.event.theEvent._components.linkOverNotification'
        },
        props: {
            busEvent: {
                type: Object,
                required: true,
            },
        },
    };
</script>
